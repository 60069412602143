var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navigation-default header-nav navbar-collapse collapse"},[_c('ul',{staticClass:"nav navbar-nav"},[_c('li',{staticClass:"elem-home",on:{"click":function($event){return _vm.itemClick('li')}}},[_c('nuxt-link',{staticClass:"home",attrs:{"to":_vm.getLocalizedRoute({ name: 'index' })}},[_vm._v("\n                "+_vm._s(_vm.$t('Accueil'))+"\n            ")])],1),_vm._v(" "),_c('li',{staticClass:"first-level",on:{"click":function($event){return _vm.itemClick('li')}}},[_c('nuxt-link',{attrs:{"to":_vm.getLocalizedRoute({ name: 'page'
           , params: {
                page: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, 'lagence', 'webPage')
               }
             })}},[_vm._v("\n               "+_vm._s(_vm.$t('L\'agence'))+"\n           ")])],1),_vm._v(" "),_c('li',{staticClass:"first-level",on:{"click":function($event){return _vm.itemClick('li')}}},[_c('nuxt-link',{class:_vm.activeSellingCss,attrs:{"to":_vm.getLocalizedRoute({ name: 'selling' })}},[_vm._v("\n               "+_vm._s(_vm.$t('Vente'))+"\n           ")])],1),_vm._v(" "),_c('li',{staticClass:"first-level",on:{"click":function($event){return _vm.itemClick('li')}}},[_c('nuxt-link',{class:_vm.activeRentingCss,attrs:{"to":_vm.getLocalizedRoute({ name: 'renting'/*, query: { page: 1 }*/ })}},[_vm._v("\n               "+_vm._s(_vm.$t('Location'))+"\n            ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{class:_vm.activeServiceCss,attrs:{"to":_vm.getLocalizedRoute({ name: 'page'
            , params: {
                page: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, 'nos-services', 'webPage')
                }
            })}},[_vm._v("\n                "+_vm._s(_vm.$t('Nos services'))+"\n            ")]),_vm._v(" "),_c('ul',{staticClass:"sub-menu"},_vm._l((_vm.article_nosServices),function(value,index){return _c('li',{key:index,on:{"click":function($event){return _vm.itemClick('sub_menu_li')}}},[_c('nuxt-link',{staticClass:"capitalyze",attrs:{"to":_vm.getLocalizedRoute({
                        name: 'news-category-slug'
                        , params: {
                            category: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, 'nos-services', 'tag')
                            , slug: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.slug, 'article')
                        }
                    })}},[_vm._v("\n                        "+_vm._s(_vm.$t( value.alternativeHeadline ))+"\n                    ")])],1)}),0)],1),_vm._v(" "),_c('li',{staticClass:"first-level",on:{"click":function($event){return _vm.itemClick('li')}}},[_c('nuxt-link',{attrs:{"to":_vm.getLocalizedRoute({
                name: 'page'
                , params: {
                    page: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, 'contact', 'webPage')
                }
            })}},[_vm._v("\n                "+_vm._s(_vm.$t('Contact'))+"\n            ")])],1),_vm._v(" "),_c('li',{staticClass:"first-level",on:{"click":function($event){return _vm.itemClick('li')}}},[_c('nuxt-link',{attrs:{"to":_vm.getLocalizedRoute({
                name: 'page'
                , params: {
                    page: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, 'faq', 'webPage')
                }
            })}},[_vm._v("\n                "+_vm._s(_vm.$t('FAQ'))+"\n            ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }